body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', Arial, Helvetica sans-serif;
  background-color: #f7f7f7;
  color: rgba(38, 41, 44, 0.88);
}

.root-wrapper {
}

img {
  display: block;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  margin-bottom: 60px;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: auto;
}

.header .logo {
  max-width: 250px;
}

.header .logo img {
  display: block;
  width: 100%;
  max-width: 100%;
}

.header .descr {
  font-size: 15px;
  margin-right: 20px;
}

.header .sign-up {
  color: #26292c;
  -webkit-box-shadow: inset 0px 0px 0px 3px #26292c;
  box-shadow: inset 0px 0px 0px 3px #26292c;
  background: transparent;
  font-weight: 700;
  padding: 6px 15px;
  font-size: 20px;
  line-height: 1.5em;
  display: inline-block;
  text-align: center;
  outline: none;
  border: 0;
  border-radius: 2px;
  cursor: pointer;
  margin: 0;
  color: rgba(38, 41, 44, 0.64);
  text-decoration: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  min-width: 86px;
  white-space: nowrap;
  transition: all 0.15s ease-in-out;
}

.header .sign-up:hover {
  color: #26292c;
  background: #f4f4f4;
}

.header .support {
  margin-left: 20px;
  color: rgba(38, 41, 44, 0.88);
}

.main .form-wrap {
  box-sizing: border-box;
  background: #fff;
  margin: 0 auto;
  max-width: 500px;
  color: #b9babb;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px 60px 30px;
}
.form-wrap .block-title {
  text-align: center;
  margin-bottom: 40px;
}

.form-wrap .log-in-title {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #26292c;
  font-size: 32px;
  line-height: 1.2em;
  margin: 0 0 16px 0;
  font-weight: 900;
}

.form-wrap .log-in-text {
  color: #26292c;
  font-size: 15px;
}

.form-wrap .form-row {
  margin-bottom: 30px;
  position: relative;
}

.form-wrap .form-row .forgot {
  position: absolute;
  right: 0;
  top: 12px;
  font-size: 12px;
  color: #b9babb;
  text-decoration: none;
  transition: 0.25s;
}

.form-wrap .form-row .forgot:hover {
  color: #26292c;
}

.form-wrap .button-submit {
  display: inline-block;
  font-family: 'SourceSansPro', sans-serif;
  padding: 11px 20px;
  background: none;
  text-align: center;
  outline: none;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  border: 0;
  border-radius: 2px;
  cursor: pointer;
  margin: 0;
  color: rgba(38, 41, 44, 0.64);
  font-weight: 500;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: middle;
  min-width: 86px;
  white-space: nowrap;
  font-size: 24px;
  line-height: 1.4em;
  white-space: normal;
  width: 100%;
  min-width: 0;
  color: #fff;
  background: #68d2e8;
  font-weight: 700;
}

.form-wrap .button-submit:hover {
  background-color: #1293c2;
}

.form-wrap input {
  border: 0;
  border-bottom: 2px solid #cbcccd;
  background: #fff;
  padding: 23px 10px;
  margin: 0;
  color: #26292c;
  font-family: 'SourceSansPro', sans-serif;
  outline: none;
  border-radius: 0;
  min-width: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transition: padding 0.2s, background-position 0.2s ease;
  transition: padding 0.2s, background-position 0.2s ease;
  width: 100%;
  max-width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 33px;
  line-height: 33px;
  font-size: 20px;
}

.form-wrap input.input-email {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUxLjIgKDU3NTE5KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5TbGljZSAxPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbC1vcGFjaXR5PSIwLjY0Ij4KICAgICAgICA8ZyBpZD0iSWNvbi9FbWFpbCIgZmlsbD0iIzI2MjkyQyIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGggZD0iTTE5LDguMzM0NTUyMyBMMTIuNjgyMzE4MywxNC4yMzEwNTUzIEMxMi4yOTgxMTE1LDE0LjU4OTY0ODIgMTEuNzAxODg4NSwxNC41ODk2NDgyIDExLjMxNzY4MTcsMTQuMjMxMDU1MyBMNSw4LjMzNDU1MjMgTDUsMTEuNzkxMTMyMiBDNSwxMi4zNDM0MTcgNC41NTIyODQ3NSwxMi43OTExMzIyIDQsMTIuNzkxMTMyMiBDMy40NDc3MTUyNSwxMi43OTExMzIyIDMsMTIuMzQzNDE3IDMsMTEuNzkxMTMyMiBMMyw3IEMzLDUuODk1NDMwNSAzLjg5NTQzMDUsNSA1LDUgTDE5LDUgQzIwLjEwNDU2OTUsNSAyMSw1Ljg5NTQzMDUgMjEsNyBMMjEsMTcgQzIxLDE4LjEwNDU2OTUgMjAuMTA0NTY5NSwxOSAxOSwxOSBMNSwxOSBDMy44OTU0MzA1LDE5IDMsMTguMTA0NTY5NSAzLDE3IEwzLDE1LjgyODU1ODEgQzMsMTUuMjc2MjczMyAzLjQ0NzcxNTI1LDE0LjgyODU1ODEgNCwxNC44Mjg1NTgxIEM0LjU1MjI4NDc1LDE0LjgyODU1ODEgNSwxNS4yNzYyNzMzIDUsMTUuODI4NTU4MSBMNSwxNyBMMTksMTcgTDE5LDguMzM0NTUyMyBaIE0xNy40OTg2OTQsNyBMNi41MDEzMDYwNCw3IEwxMiwxMi4xMzIxMTQ0IEwxNy40OTg2OTQsNyBaIiBpZD0iQ29tYmluZWQtU2hhcGUiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
  background-position: left top;
  background-repeat: no-repeat;
  padding-left: 30px;
  background-position: 0 11px;
}

.form-wrap input.input-password {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUxLjIgKDU3NTE5KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5TbGljZSAxPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZmlsbC1vcGFjaXR5PSIwLjY0Ij4KICAgICAgICA8ZyBpZD0iSWNvbi9Mb2NrIiBmaWxsPSIjMjYyOTJDIiBmaWxsLXJ1bGU9Im5vbnplcm8iPgogICAgICAgICAgICA8cGF0aCBkPSJNNyw5IEw3LDggQzcsNS4yMzg1NzYyNSA5LjIzODU3NjI1LDMgMTIsMyBDMTQuNzYxNDIzNywzIDE3LDUuMjM4NTc2MjUgMTcsOCBMMTcsOSBMMTgsOSBDMTkuMTA0NTY5NSw5IDIwLDkuODk1NDMwNSAyMCwxMSBMMjAsMTkgQzIwLDIwLjEwNDU2OTUgMTkuMTA0NTY5NSwyMSAxOCwyMSBMNiwyMSBDNC44OTU0MzA1LDIxIDQsMjAuMTA0NTY5NSA0LDE5IEw0LDE4LjE2NTQwMDIgQzQsMTcuNjEzMTE1NCA0LjQ0NzcxNTI1LDE3LjE2NTQwMDIgNSwxNy4xNjU0MDAyIEM1LjU1MjI4NDc1LDE3LjE2NTQwMDIgNiwxNy42MTMxMTU0IDYsMTguMTY1NDAwMiBMNiwxOSBMMTgsMTkgTDE4LDExIEw2LDExIEw2LDE0LjE5MTI2NTUgQzYsMTQuNzQzNTUwMyA1LjU1MjI4NDc1LDE1LjE5MTI2NTUgNSwxNS4xOTEyNjU1IEM0LjQ0NzcxNTI1LDE1LjE5MTI2NTUgNCwxNC43NDM1NTAzIDQsMTQuMTkxMjY1NSBMNCwxMSBDNCw5Ljg5NTQzMDUgNC44OTU0MzA1LDkgNiw5IEw3LDkgWiBNOSw5IEwxNSw5IEwxNSw4IEMxNSw2LjM0MzE0NTc1IDEzLjY1Njg1NDIsNSAxMiw1IEMxMC4zNDMxNDU4LDUgOSw2LjM0MzE0NTc1IDksOCBMOSw5IFogTTEyLDE3IEMxMC44OTU0MzA1LDE3IDEwLDE2LjEwNDU2OTUgMTAsMTUgQzEwLDEzLjg5NTQzMDUgMTAuODk1NDMwNSwxMyAxMiwxMyBDMTMuMTA0NTY5NSwxMyAxNCwxMy44OTU0MzA1IDE0LDE1IEMxNCwxNi4xMDQ1Njk1IDEzLjEwNDU2OTUsMTcgMTIsMTcgWiIgaWQ9IkNvbWJpbmVkLVNoYXBlIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
  background-position: left top;
  background-repeat: no-repeat;
  padding-left: 30px;
  background-position: 0 10px;
}

.form-checkbox-custom .form-label {
  font-size: 15px;
  color: #b4b6b8;
  position: relative;
  display: inline-block;
  width: auto;
  padding: 0 0 0 30px;
  cursor: pointer;
  text-align: left;
  color: rgba(38, 41, 44, 0.64);
  max-width: 370px;
  font-size: 15px;
  line-height: 20px;
}

.form-checkbox-custom .form-label:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #b9babb;
}

.form-checkbox-custom .form-label::after {
  top: 2px;
  left: 1px;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: transparent;
}

.form-wrap input::placeholder {
  font-size: 20px;
  line-height: 1.5em;
  color: #b9babb;
}

.form-wrap .form-checkbox-custom {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.form-checkbox-custom input {
  position: absolute;
  z-index: -1;
  margin: 10px 0 0 20px;
  opacity: 0;
}

.form-checkbox-custom .form-label {
  position: relative;
  padding: 0 0 0 30px;
  cursor: pointer;
}

.form-checkbox-custom input:checked + .form-label::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  background: #fff;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.form-checkbox-custom input:checked + .form-label::after {
  content: '';
  position: absolute;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgMjg4Ljk0MSAyODguOTQxIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyODguOTQxIDI4OC45NDE7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGlkPSJDaGVjayIgZD0iTTI4NS4zNzcsNDYuMzY4Yy00Ljc0LTQuNzA0LTEyLjQzOS00LjcwNC0xNy4xNzksMEw5Ni4zMDksMjE3LjExNEwyMC43MzQsMTQyLjYxDQoJCWMtNC43NC00LjcwNC0xMi40MzktNC43MDQtMTcuMTc5LDBzLTQuNzQsMTIuMzE5LDAsMTcuMDExbDg0LjIsODIuOTk3YzQuNjkyLDQuNjQ0LDEyLjQ5OSw0LjY0NCwxNy4xOTEsMGwxODAuNDMtMTc5LjIzOQ0KCQlDMjkwLjEyOSw1OC42ODcsMjkwLjEyOSw1MS4wNiwyODUuMzc3LDQ2LjM2OEMyODAuNjM3LDQxLjY2NCwyOTAuMTI5LDUxLjA2LDI4NS4zNzcsNDYuMzY4eiIvPg0KCTxnPg0KCTwvZz4NCgk8Zz4NCgk8L2c+DQoJPGc+DQoJPC9nPg0KCTxnPg0KCTwvZz4NCgk8Zz4NCgk8L2c+DQoJPGc+DQoJPC9nPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=');
  top: 3px;
  left: 3px;
  width: 16px;
  height: 16px;
  content: '';
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  background-size: 14px;
  background-repeat: no-repeat;
}

.block-footer {
  padding-top: 40px;
}

.block-footer .container {
  max-width: 1200px;
  margin: auto;
  text-align: center;
}

.block-footer .container a {
  color: #97999c;
  font-size: 20px;
  text-decoration: none;
}

@media (max-width: 711px) {
  .header .logo {
    max-width: 180px;
  }

  .header .descr {
    display: none;
  }

  .header .sign-up {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.5em;
  }

  .main .form-wrap {
    padding: 25px;
  }

  .block-footer {
    padding-top: 20px;
  }

  .form-wrap input {
    font-size: 18px;
  }

  .form-wrap .button-submit {
    font-size: 18px;
    padding: 10px;
  }

  .form-wrap input::placeholder {
    font-size: 18px;
  }
}

.alert-danger {
  padding: 12px 25px;
  margin: 0 0 10px 0;
  border: 0;
  border-radius: 0;
  background: rgba(184, 60, 73, 0.15);
  color: #7e2932;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.validation-error {
  margin-top: 10px;
  color: #7e2932;
}
